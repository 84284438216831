body,
html {
  height: 100%;
  /* circular gradient black background */
  background: radial-gradient(circle, rgb(26, 26, 26) 0%, rgb(7, 7, 7) 100%);
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: Cryptogram;
  src: url("font.otf") format("opentype");
}

@media only screen and (max-width: 600px) {
  .textbox {
    width: 0px !important;
  }

  .col {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mainText {
    font-size: 9vw !important;
    letter-spacing: 0;
  }
  .mainRow {
    padding-left: 4px;
    padding-right: 4px;
    width: 95% !important;
  }
}
.mainRow {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.textbox {
  /*  height: 300px; */
  padding: 0;
  width: 12vw;
}

.mainText {
  font-family: Cryptogram;
  font-size: 8vw;
  top: 0;
  text-align: center;
  color: white;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.empty {
}
/* draw a red line after mainText */
.empty::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2vw;
  background-color: rgb(255, 255, 255);
  border-radius: 32px;
}

.active.empty::after {
  background-color: #0292e5 !important;
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 40%;
}

.docButton {
  background-color: #2c2c2c;
  color: white;
  padding: 7px 50px;
  transition: 200ms;
  border-radius: 8px;
  outline: none;
  border-style: none !important;
  text-decoration: none;
  margin: 8px;
}

/* Default font size for button text */
.docButton p {
  text-decoration: none;
  color: white;
  font-size: 22px;
  text-align: "center";
  padding: 0;
  margin: 0;
}

.image-container-apple {
  width: 190px;
  height: 90px;
}

.image-container-google {
  height: 90px;
}

.docButton p {
  text-decoration: none;
  color: white;
  font-size: 22px;
  text-align: "center";
  padding: 0;
  margin: 0;
}

.test-testimonials {
  font-size: 16px;
  color: white;
}

.bottom-testimonials {
  width: 100%;
  margin-bottom: 20px;
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  /* Reduce button padding and font size */
  .docButton {
    padding: 7px 20px; /* Adjust the padding as needed */
  }

  /* Reduce font size for button text */
  .docButton p {
    font-size: 18px; /* Adjust the font size as needed */
  }

  .image-container-apple {
    width: 126px;
    height: 60px;
  }

  .image-container-google {
    height: 60px;
  }

  .docButton p {
    text-decoration: none;
    color: white;
    font-size: 16px;
    text-align: "center";
    padding: 0;
    margin: 0;
  }

  .bottom-testimonials {
    margin-bottom: 50px;
  }

  .test-testimonials {
    font-size: 14px;
  }
}

button:focus {
  outline: 0;
}

.middle {
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
}

@keyframes glow {
  0%,
  100% {
    text-shadow:
      0 0 5px #ffd700,
      0 0 10px #ffd700,
      0 0 15px #ffd700,
      0 0 20px #ffd700;
  }
  50% {
    text-shadow:
      0 0 10px #ffd700,
      0 0 20px #ffd700,
      0 0 30px #ffd700,
      0 0 40px #ffd700;
  }
}

.star {
  display: inline-block;
  color: #ffd700;
  animation: glow 3.5s infinite alternate;
  animation-delay: calc(0.5s * var(--i));
}
